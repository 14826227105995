import React from 'react';
import { Button, ButtonPriority } from 'wix-ui-tpa';
import { classes, st } from './ModalFooter.st.css';

export type ButtonProp = {
  dataHook?: string;
  text: string;
  onClick: () => {};
  loading: boolean;
};

export interface ModalFooterProps {
  primaryButton: ButtonProp;
  secondaryButton: ButtonProp;
  isMobile?: boolean;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  primaryButton,
  secondaryButton,
  isMobile = false,
}) => (
  <div className={st(classes.root, { mobile: isMobile })}>
    <Button
      data-hook={primaryButton.dataHook}
      upgrade
      priority={ButtonPriority.primary}
      onClick={primaryButton.onClick}
      disabled={primaryButton.loading}
    >
      {primaryButton.text}
    </Button>
    <Button
      data-hook={secondaryButton.dataHook}
      upgrade
      priority={ButtonPriority.basicSecondary}
      onClick={secondaryButton.onClick}
      disabled={secondaryButton.loading}
    >
      {secondaryButton.text}
    </Button>
  </div>
);
